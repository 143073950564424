export const menuItems = [
    {
        id: 1,
        label: "Dashboard",
        icon: "bx-home",
        link: "/"
    },
    {
        id: 2,
        label: "Catálogo",
        icon: "bx-grid-alt",
        subItems: [
            {
                id: 3,
                label: "Categorias",
                link: "/categorias",
                parentId: 2
            },
            {
                id: 4,
                label: "Produtos",
                link: "/produtos",
                parentId: 2
            },
            {
                id: 5,
                label: "Páginas",
                link: "/paginas",
                parentId: 12
            },
            /*{
                id: 6,
                label: "Atributos",
                link: "/ecommerce/products",
                parentId: 12
            },
            {
                id: 7,
                label: "Opções",
                link: "/ecommerce/products",
                parentId: 12
            },*/
            {
                id: 8,
                label: "Fabricantes",
                link: "/fabricantes",
                parentId: 12
            },
            {
                id: 9,
                label: "Marcas",
                link: "/marcas",
                parentId: 12
            },
            {
                id: 10,
                label: "Avaliações",
                link: "/avaliacoes",
                parentId: 12
            },
        ]
    },
    {
        id: 11,
        label: "Vendas",
        icon: "bx-shopping-bag",
        link: "/vendas"
        /*subItems: [
            {
                id: 12,
                label: "Pedidos",
                link: "/ecommerce/products",
                parentId: 12,
            },
            {
                id: 13,
                label: "Nota de Crédito",
                link: "/ecommerce/products",
                parentId: 12
            },
            {
                id: 14,
                label: "Incompletas",
                link: "/ecommerce/products",
                parentId: 12
            },
        ]*/
    },
    {
        id: 15,
        label: "Clientes",
        link: "/clientes",
        icon: "bx-user-circle",
    },
    {
        id: 16,
        label: "Financeiro",
        link: "/financeiro",
        icon: "bx-wallet",
    },
    {
        id: 17,
        label: "E-mail",
        link: "/emails",
        icon: "bx-envelope",
    },
    /*{
        id: 18,
        label: "Marketing",
        icon: "bx-purchase-tag",
        subItems: [
            {
                id: 19,
                label: "Promoções",
                link: "/ecommerce/products",
                parentId: 12
            },
            {
                id: 20,
                label: "Cupons",
                link: "/cupons",
                parentId: 12
            },
        ]
    },*/
    {
        id: 21,
        label: "Depoimentos",
        link: "/depoimentos",
        icon: "bx-message",
    },
    {
        id: 22,
        label: "Blog",
        icon: "bx-news",
        subItems: [
            {
                id: 23,
                label: "Posts",
                link: "/blog-posts",
                parentId: 12
            },
            {
                id: 24,
                label: "Categorias",
                link: "/blog-categorias",
                parentId: 12
            },
        ]
    },
   /* {
        id: 25,
        label: "Usuários",
        link: "/apps/file-manager",
        icon: "bx-user",
    },*/
    {
        id: 28,
        label: "Contato",
        link: "/contatos",
        icon: "bxs-user-detail",
    },
    {
        id: 29,
        label: "Newsletters",
        link: "/newsletters",
        icon: "bx-rocket",
    },
    {
        id: 26,
        label: "Aparência",
        link: "/aparencia",
        icon: "bx-cube",
    },
    {
        id: 27,
        label: "Configurações",
        link: "/configuracoes",
        icon: "bx-cog",
    },
    
];

